import loadable from '@loadable/component';

import { getRewrittenUrl } from '../../../../analytics-library/entry';
import { ProductTypes } from '../../../../types/ProductType';
import type { Deal } from '../../../types/Deal';
import { WidgetId } from '../../../types/Widget';

const lazyGetCustomTrackingIdServerside = loadable.lib(
  () => import('../../../../analytics-library/entry/getCustomTrackingIdServerside'),
);

const lazyGetCustomTrackingId = loadable.lib(
  () => import('../../../../analytics-library/entry/getCustomTrackingId'),
);

export const getCustomTrackedOffer = async ({
  deal,
  site,
  articleUrl,
  area,
  params,
  widget,
  forcedCustomTrackingId,
  link,
  linkText,
  articleName,
  rawLink,
}): Promise<Deal> => {
  if (deal?.offer?.link) {
    let customTrackingId: string;
    // load appropiate function based on server/client environment
    if (process.env.IS_SERVERSIDE) {
      const { getCustomTrackingIdServerside } =
        (await lazyGetCustomTrackingIdServerside.load()) as any;
      customTrackingId = getCustomTrackingIdServerside({
        params: {
          pageUrl: articleUrl,
          site,
          deal,
          area,
          battle: (params && params.battle) || '',
        },
        forcedCustomTrackingId,
        randomize: deal.product_type === ProductTypes.rewards,
      });
    } else {
      const { getCustomTrackingId } = (await lazyGetCustomTrackingId.load()) as any;
      customTrackingId = await getCustomTrackingId({
        params: {
          pageUrl: articleUrl,
          site,
          deal,
          area,
          battle: (params && params.battle) || '',
        },
        forcedCustomTrackingId,
        randomize: deal.product_type === ProductTypes.rewards,
      });
    }

    /* if data-link-text and data-link are set in the placeholder they
    have priority over the api deal link and linkText */
    const usePlaceholderLink =
      (widget.id === WidgetId.DEAL || widget.id === WidgetId.ANF_DEAL) && link;

    const usePlaceholderLinkText = usePlaceholderLink && linkText;

    const offerLinkText = usePlaceholderLinkText ? linkText : deal?.offer?.linkText;

    return {
      ...deal,
      offer: {
        ...deal.offer,
        ...(offerLinkText ? { linkText: offerLinkText } : {}),
        link: getRewrittenUrl(
          // use rawLink to build url if it is available
          rawLink ?? (usePlaceholderLink ? link : deal.offer.link),
          site && site.analyticsName,
          area,
          customTrackingId,
          articleUrl,
          articleName,
        ),
        // Dont rewrite rawLink if its already set
        ...(deal?.offer?.rawLink ? {} : { rawLink: usePlaceholderLink ? link : deal.offer.link }),
        custom_tracking_id: customTrackingId,
      },
    };
  }

  return {
    ...deal,
    offer: {
      ...deal.offer,
    },
  };
};
