import { ApiResponse } from '../../../sharedModules/api/apiUtils';
import { SeasonalResponse } from '../../../sharedModules/api/searchApi/createSeasonalResponseGetter';
import { WidgetResult } from '../../hooks/useAnalytics';
import type { InitialiserResult } from '../../types/InitialiserResult';
import { WidgetProps } from '../../types/WidgetProps';

const determineResultType = (
  seasonalResponse: ApiResponse<SeasonalResponse>,
  keywords?: string[],
): WidgetResult => {
  if (!seasonalResponse.ok || !seasonalResponse.data.link) {
    return WidgetResult.MISSING;
  }

  const seasonalLinkTags = seasonalResponse.data.link?.tags;
  // if keywords are given and api returns tags, then at least one tag must be within keywords
  if (keywords && seasonalLinkTags && seasonalLinkTags?.length > 0) {
    return seasonalLinkTags.some((tag) => keywords.includes(tag))
      ? WidgetResult.SUCCESS
      : WidgetResult.SKIPPED;
  }

  return WidgetResult.SUCCESS;
};

export const setupSeasonalWidget = async ({
  props,
}: {
  props: WidgetProps;
}): Promise<InitialiserResult> => {
  const { params, getSeasonalResponse, keywords } = props;

  const seasonalResponse = await getSeasonalResponse(params);

  const type = determineResultType(seasonalResponse, keywords);

  return {
    props: {
      ...props,
      seasonalResponse,
    },
    battle: null,
    type,
  };
};
