import Uri from 'urijs';

import type { Deal } from '../../../../types/Deal';
import type { MerchantLinkData } from '../../../../types/MerchantLinkData';
import getCurrencySymbol from '../../../../utils/getCurrencySymbol';
import getImageUrl from '../../../getImageUrl';
import type Localiser from '../../../Localiser';

export const getFallbackOffer = ({
  dataLinkMerchant,
  link,
  linkText,
  localiser,
  modelName,
  editorialCurrency,
  editorialPrice,
  editorialWasPrice,
  editorialMerchantName,
}: {
  dataLinkMerchant: MerchantLinkData;
  link?: string;
  linkText: string;
  localiser: Localiser;
  modelName: string;
  editorialCurrency: string | null;
  editorialPrice: string | null;
  editorialWasPrice: string | null;
  editorialMerchantName: string | null;
}): Deal => {
  const elDomain =
    editorialMerchantName ||
    (dataLinkMerchant?.name ?? (link ? new Uri(link).domain() : 'merchant'));

  return {
    an: dataLinkMerchant?.network || null,
    bundle_models: [],
    click_count: 0,
    click_count_weekly: 0,
    click_count_monthly: 0,
    image: null,
    label_image: null,
    last_update: 0,
    mobile_match_score: 0,
    model_id: 0,
    percentage: 0,
    popularity: 0,
    product_key: '',
    score: 0,
    shipping: {
      prime: false,
      url: null,
    },
    id: 0,
    match_id: 0,
    product_type: 300,
    offer: {
      link: link ?? '',
      name: modelName,
      link_text: linkText ?? '',
      merchant_link_text: linkText ?? '',
      label: localiser.translate('offerLabelNoPrice'),
      price: editorialPrice || '',
      in_stock: true,
      stock_quantity: null,
      currency_iso: editorialCurrency || 'GBP',
      currency_symbol: (editorialCurrency && getCurrencySymbol(editorialCurrency)) || '£',
      percentage_saving: null,
      percentage_saving_label: null,
      money_saving: null,
      money_saving_label: null,
      display_name: modelName,
      display_labels: '',
      was_price: editorialWasPrice === null ? undefined : Number(editorialWasPrice),
    },
    merchant: {
      id: dataLinkMerchant?.id || null,
      name: elDomain,
      url: dataLinkMerchant?.url || null,
      logo_url: '',
      territory: '',
      preference: 0,
    },
    model: modelName,
    requested_model: modelName,
    model_matched: [modelName],
    model_image: getImageUrl('misc/blank.png'),
  };
};
