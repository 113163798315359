import React from 'react';

import { getWidgetInitialiser } from '../../../sharedModules/getWidgetInitialiser';
import { loadCSS } from '../../../utils/loadCSS';
import { WidgetResult } from '../../hooks/useAnalytics';
import { InitialiserProps, WidgetWrapper } from '../../types/InitialiserProps';
import { WidgetId } from '../../types/Widget';
import type { WidgetProps } from '../../types/WidgetProps';
import { Consumer } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import PromotionItem from './PromotionItem';
import PromotionMain from './PromotionMain';

loadCSS('promotion');

const Promotion: React.FC<WidgetProps> = (props) => {
  const {
    genericSharedComponents: { HawkWidgetStandard },
  } = props;
  return (
    <HawkWidgetStandard {...props}>
      <Consumer>
        {(value): JSX.Element => {
          const { dealData } = value;
          const deals = dealData && dealData.deals ? dealData.deals : [];

          return (
            <PromotionMain>
              {deals.map((deal) => (
                <PromotionItem
                  {...{
                    deal,
                    key: deal?.id,
                  }}
                />
              ))}
            </PromotionMain>
          );
        }}
      </Consumer>
    </HawkWidgetStandard>
  );
};

Promotion.getInitialiserProps = async ({
  renderProps: { el, site, articleUrl, ...restRenderProps },
  attributes,
  getWidgetResponse,
  getSeasonalResponse,
  getAiredaleFeeds,
  getModelSuggestions,
  postForWidgetResponse,
  editorial,
  defaultParams,
  url,
  origin,
  dataLinkMerchant,
}): InitialiserProps => {
  // Get the initialiser for the widget & render it when the initialiser resolves
  const { props, type } = await getWidgetInitialiser({
    editorial,
    defaultParams,
    url,
    origin,
    dataLinkMerchant,
    site,
    getWidgetResponse,
    getSeasonalResponse,
    getAiredaleFeeds,
    getModelSuggestions,
    postForWidgetResponse,
    placeholder: el,
    articleUrl,
    attributes,
    ...restRenderProps,
  });

  const newWidgetType =
    type === WidgetResult.MISSING ? WidgetId.PROMOTION_FALLBACK : WidgetId.PROMOTION;
  const newProps = { ...props, widgetTypeComponent: newWidgetType };

  return { props: newProps, type, widgetWrapper: WidgetWrapper.DIV };
};

export default Promotion;
