import getWidget from '../../../utils/getWidget';
import { WidgetId } from '../../types/Widget';

import getBattleData from './getBattleData';
import type { ModelWidgetData } from './types';

export const setupBattle = (setupModelWidgetData: ModelWidgetData): ModelWidgetData => {
  const { options, model } = setupModelWidgetData;
  const { apiFeatures, widgetFeatures, props } = options;
  const { widgetResponse, widgetMissing } = model;

  if (widgetMissing || !widgetResponse.ok || !widgetResponse.data.battle) {
    return setupModelWidgetData;
  }

  const {
    widget: { data },
    models,
    battle,
  } = widgetResponse.data;

  // Setup battle if battle is enabled & a test config was returned
  const { battleFeatures, battleDealOverrides, test, variant } = getBattleData(
    battle,
    props,
    models,
    data,
  );
  apiFeatures.setAttribute('battleFeatures', battleFeatures);
  widgetFeatures.setAttribute('battleFeatures', battleFeatures);

  // Update the widget config if the widget type was changed by battle
  if (battleFeatures && battleFeatures.switchMerchantlinkToReview) {
    battleFeatures.widgetType = WidgetId.REVIEW;
  }
  if (battleFeatures && battleFeatures.widgetType) {
    const widget = getWidget(battleFeatures.widgetType);
    return {
      options: {
        ...options,
        apiFeatures,
        widgetFeatures,
        props: {
          ...props,
          widget,
          widgetTypeComponent: widget.id,
          battle: {
            test,
            variant: {
              id: variant?.id || 0,
              name: variant?.name || '',
            },
          },
        },
      },
      model: {
        ...model,
        battleDealOverrides,
        battleFeatures,
        widgetChanged: true,
      },
    };
  }

  return {
    options: {
      ...options,
      apiFeatures,
      widgetFeatures,
      props: {
        ...props,
        battle: {
          test,
          variant: {
            id: variant?.id || 0,
            name: variant?.name || '',
          },
        },
      },
    },
    model: {
      ...model,
      battleDealOverrides,
      battleFeatures,
    },
  };
};
