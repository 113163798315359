import getBundleTabData from './getBundleTabData';
import getTabScopedProps from './getTabScopedProps';
import type { ModelWidgetData } from './types';

export const setupTabBasedData = async (
  setupModelWidgetData: ModelWidgetData,
): Promise<ModelWidgetData> => {
  const { options, model } = setupModelWidgetData;
  const { apiFeatures, widgetFeatures, props, features } = options;
  const { widget, getWidgetResponse } = props;
  const { widgetResponse, defaultTab, reFetchData, widgetMissing, tabConfigs } = model;
  if (widgetMissing || !widgetResponse.ok) {
    return setupModelWidgetData;
  }

  const {
    widget: { data },
  } = widgetResponse.data;

  // Check if we should send additional requests & set up the tab based data & params
  const tabScopedProps = await getTabScopedProps({
    defaultTab,
    tabConfigs,
    data,
    params: props.params,
    showFilters: features.showFilters ?? false,
    reFetchData,
    widget,
    getWidgetResponse,
  });
  const bundle = getBundleTabData(tabScopedProps.tabData, tabConfigs, defaultTab);

  // Set the tabs for the features so we can conditionally set a feature based on
  // the product type (e.g. showPrice)
  widgetFeatures.setAttribute('defaultTab', defaultTab);
  apiFeatures.setAttribute('defaultTab', defaultTab);
  const newFeatures = widgetFeatures.getFeatures('id');
  return {
    options: {
      ...options,
      props: {
        ...props,
        ...newFeatures,
        params: apiFeatures.getFeatures('value', true),
      },
      widgetFeatures,
      apiFeatures,
      features: newFeatures,
    },
    model: {
      ...model,
      tabData: bundle.data,
      tabConfigs: bundle.tabConfigs,
      tabParams: tabScopedProps.tabParams,
    },
  };
};
