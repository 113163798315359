import type { Deal } from '../../../types/Deal';
import { WidgetId } from '../../../types/Widget';
import { ProductTypes } from '../../../../types/ProductType';

import getCTA from './getCTA';

export const addCtaToDeal = ({
  deal,
  widget,
  showFallback,
  localiser,
  localisedLinkText,
  modelName,
  showPrice,
}): Deal => {
  let cta;

  // we do not want to add custom cta for fallback offers
  if (deal.product_type === ProductTypes.fallback && localisedLinkText) {
    return deal;
  }

  if (widget.id === WidgetId.DEAL || widget.id === WidgetId.ANF_DEAL) {
    cta =
      showFallback === 'editors-choice'
        ? localiser.translate('editorsChoiceLinkText', [modelName])
        : localisedLinkText;
  } else {
    cta = getCTA(localiser, widget, { ...deal }, localisedLinkText, showPrice);
  }

  if (cta) {
    return {
      ...deal,
      offer: {
        ...deal.offer,
        merchant_link_text: cta,
        link_text: cta,
      },
    };
  }

  return deal;
};
