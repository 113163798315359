import { IRenderWidgetProps } from '../../assets/modules/renderWidget/model';
import { AiredaleFeedsGetter } from '../../sharedModules/api/airedale/createAiredaleFeedsGetter';
import { ModelSuggestionsGetter } from '../../sharedModules/api/searchApi/createModelSuggestionsGetter';
import { SeasonalResponseGetter } from '../../sharedModules/api/searchApi/createSeasonalResponseGetter';
import { WidgetResponseGetter } from '../../sharedModules/api/searchApi/createWidgetResponseGetter';
import { WidgetResponsePost } from '../../sharedModules/api/searchApi/createWidgetResponsePost';
import { WidgetResult } from '../hooks/useAnalytics';

import { MerchantLinkData } from './MerchantLinkData';
import { WidgetInitialiserOptions } from './WidgetInitialiserOptions';
import { WidgetProps } from './WidgetProps';

export enum WidgetWrapper {
  SPAN = 'span',
  DIV = 'div',
}

export type GetInitializerPropsParams = {
  renderProps: IRenderWidgetProps;
  attributes: DOMStringMap;
  getWidgetResponse: WidgetResponseGetter;
  getSeasonalResponse: SeasonalResponseGetter;
  getAiredaleFeeds: AiredaleFeedsGetter;
  getModelSuggestions: ModelSuggestionsGetter;
  postForWidgetResponse: WidgetResponsePost;
  editorial: boolean;
  defaultParams: WidgetInitialiserOptions['defaultParams'];
  url: string;
  origin: string;
  dataLinkMerchant: MerchantLinkData;
};

export type InitialiserProps = Promise<{
  props: WidgetProps;
  type: WidgetResult;
  widgetWrapper: WidgetWrapper;
}>;

export type GetInitialiserProps = (params: GetInitializerPropsParams) => InitialiserProps;
