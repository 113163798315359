import getWidget from '../../../../utils/getWidget';
import type { ModelWidgetOptions } from '../../../types/ModelWidgetOptions';
import { WidgetId } from '../../../types/Widget';
import type { WidgetProps } from '../../../types/WidgetProps';
import type { APIFeatures, WidgetFeatures } from '../../features';
import type WidgetFeaturesIds from '../../features/types/WidgetFeaturesIds';
import getWidgetTypeComponent from '../getWidgetTypeComponent';

/**
 * If the widget has changed to a nighthawk widget then we must update props and
 * params before getting the data from the API in order to ensure we return all
 * filters so that we can correctly set up the tab configs
 */
export const changeToNighthawk = ({
  props,
  widgetFeatures,
  apiFeatures,
  features,
}: ModelWidgetOptions): {
  props: WidgetProps;
  widgetFeatures: WidgetFeatures;
  apiFeatures: APIFeatures;
  features: Partial<WidgetFeaturesIds>;
} => {
  const widgetTypeComponent = getWidgetTypeComponent(props, null);

  if (props.widget.id !== widgetTypeComponent && widgetTypeComponent === WidgetId.NIGHTHAWK) {
    const widget = getWidget(widgetTypeComponent);
    widgetFeatures.setAttribute('widget', widget);
    apiFeatures.setAttribute('widget', widget);
    const features = widgetFeatures.getFeatures('id');
    return {
      widgetFeatures,
      apiFeatures,
      features,
      props: {
        ...props,
        ...features,
        params: apiFeatures.getFeatures('value', true),
        widget,
      },
    };
  }

  return {
    widgetFeatures,
    apiFeatures,
    features,
    props,
  };
};
