import type { Deal } from '../../../../types/Deal';
import type { MerchantLinkData } from '../../../../types/MerchantLinkData';
import type Localiser from '../../../Localiser';

import { getFallbackOffer } from './getFallbackOffer';

export const addFallbackOffer: ({
  dataLinkMerchant,
  link,
  localisedLinkText,
  offers,
  preserveEditorialLink,
  pageSize,
  localiser,
  modelName,
  editorialCurrency,
  editorialPrice,
  editorialWasPrice,
  editorialMerchantName,
}: {
  dataLinkMerchant: MerchantLinkData;
  link?: string;
  localisedLinkText: string;
  offers: Deal[];
  preserveEditorialLink?: boolean;
  pageSize: number;
  localiser: Localiser;
  modelName: string;
  editorialCurrency: string | null;
  editorialPrice: string | null;
  editorialWasPrice: string | null;
  editorialMerchantName: string | null;
}) => Deal[] = ({
  dataLinkMerchant,
  link,
  localisedLinkText,
  offers,
  preserveEditorialLink,
  pageSize,
  localiser,
  modelName,
  editorialCurrency,
  editorialPrice,
  editorialWasPrice,
  editorialMerchantName,
}): Deal[] => {
  // We don't want to add the fallback twice "!addFallback"
  // We don't want to add the fallback if no editorial link and linkText is provided
  // if there is view similar make sure that adding the fallback doesn't go over the pagesize
  const addFallback = Boolean(
    link &&
      (offers.length === 0 ||
        (offers.length === offers.filter((o) => o.product_type === 200).length &&
          offers.length < pageSize)),
  );
  const hasFallback = Boolean(link);

  // We don't want to add the fallback twice
  if (preserveEditorialLink && offers.length !== 0 && !addFallback && hasFallback) {
    const fallbackOffer = getFallbackOffer({
      dataLinkMerchant,
      link,
      linkText: localisedLinkText,
      localiser,
      modelName,
      editorialCurrency,
      editorialPrice,
      editorialWasPrice,
      editorialMerchantName,
    });

    if (offers.length === pageSize) {
      const offersArray = [fallbackOffer, ...offers];
      offersArray.pop();
      return offersArray;
    }

    return [fallbackOffer, ...offers];
  }
  if (addFallback) {
    return [
      ...offers,
      getFallbackOffer({
        dataLinkMerchant,
        link,
        linkText: localisedLinkText,
        localiser,
        modelName,
        editorialCurrency,
        editorialPrice,
        editorialWasPrice,
        editorialMerchantName,
      }),
    ];
  }

  return offers;
};
