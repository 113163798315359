import { WidgetId } from '../../types/Widget';
import type { Widget } from '../../types/Widget';

import type ApiFeaturesValues from './types/ApiFeaturesValues';

/**
 * Return if there is a model lookup, so we know if the widget
 * should be skipped (no API request should be made)
 */
export const shouldSendAPIRequest = (
  widget: Widget,
  params: Partial<ApiFeaturesValues>,
): boolean => {
  const { model_name, model_id, offer, match_id, only_fallback_offers } = params;

  // Deal blocks without a Match ID don't need any API data
  if ((widget?.id === WidgetId.DEAL || widget?.id === WidgetId.ANF_DEAL) && !match_id) {
    return false;
  }

  // Force display of only fallbacks even if we have data available,
  // e.g. sponsored articles (WhoWhatWear)
  if (only_fallback_offers) {
    return false;
  }

  return Boolean(model_name || model_id || offer);
};
