import { shouldSendAPIRequest } from '../features/shouldSendAPIRequest';

import type { ModelWidgetData } from './types';
import { changeToNighthawk } from './utils/changeToNighthawk';

export const getModelWidgetData = async ({
  options,
  model,
}: ModelWidgetData): Promise<ModelWidgetData> => {
  const {
    props: { widget, params, getWidgetResponse },
  } = options;

  if (!shouldSendAPIRequest(widget, params)) {
    return {
      options,
      model,
    };
  }

  /* If the widget has changed to a nighthawk widget then we must update props
      and params before getting the data from the API in order to ensure we return
      all filters so that we can correctly set up the tab configs */
  const { props, widgetFeatures, apiFeatures, features } = changeToNighthawk(options);

  const widgetResponse = await getWidgetResponse(params);

  return {
    options: {
      ...options,
      apiFeatures,
      widgetFeatures,
      features,
      props,
    },
    model: {
      ...model,
      widgetResponse,
    },
  };
};
