import { ProductTypes } from '../../../../types/ProductType';
import type { Deal } from '../../../types/Deal';
import { WidgetId } from '../../../types/Widget';
import type { WidgetProps } from '../../../types/WidgetProps';
import type Localiser from '../../Localiser';

import getInbodyLabel from './getInbodyLabel';

export default (
  localiser: Localiser,
  widget: WidgetProps['widget'],
  deal: Deal,
  linkText: string,
  showPrice: string,
): string => {
  switch (widget.id) {
    case WidgetId.BUTTON:
      if (deal?.product_type && deal.product_type !== ProductTypes.similar) {
        return localiser.translate('checkPriceLinkText');
      }
      return '';
    case WidgetId.DEAL:
      if (!linkText) {
        return localiser.translate('alternativeLinkText');
      }
      return linkText;
    case WidgetId.MERCHANT_LINK:
      if (showPrice === 'noPrice') {
        return '';
      }
      if (deal?.offer && deal?.product_type && deal.product_type === ProductTypes.multimedia) {
        if (showPrice === 'stream') {
          if (deal.offer?.price && Number(deal.offer.price) > 0) {
            return (
              deal.merchant &&
              localiser.translate('multimediaStreamOn', [String(deal.merchant.name)])
            );
          }
          return (
            deal.merchant &&
            localiser.translate('multimediaStreamOnWithoutFor', [String(deal.merchant.name)])
          );
        }
        if (showPrice === 'availableToStream') {
          return (
            deal.merchant &&
            localiser.translate('multimediaAvailableToStream', [String(deal.merchant.name)])
          );
        }
      }
      if (deal?.offer && deal?.product_type === ProductTypes.fallback && !deal?.offer?.price) {
        return `${localiser.translate('priceLinkLinkText')}`;
      }
      if (
        deal?.offer &&
        ((deal?.product_type && deal.product_type === ProductTypes.similar) || !deal?.offer?.price)
      ) {
        return `${localiser.translate('merchantLinkWidgetItemBuySimilarLabel')} ${
          deal?.merchant?.name || 'merchant'
        }`;
      }
      return (
        deal?.merchant && localiser.translate('priceLinkAtMerchant', [String(deal.merchant.name)])
      );
    case WidgetId.STREAMING:
      if (showPrice === 'noPrice') {
        return '';
      }
      if (
        deal?.offer &&
        deal?.product_type &&
        (deal.product_type === ProductTypes.multimedia ||
          deal.product_type === ProductTypes.subscription)
      ) {
        return (
          deal?.merchant &&
          localiser.translate('multimediaWatchAtWithoutFor', [String(deal.merchant.name)])
        );
      }
      if (
        deal?.offer &&
        deal?.product_type &&
        (deal.product_type === ProductTypes.similar || !deal?.offer?.price)
      ) {
        return `${localiser.translate('merchantLinkWidgetItemBuySimilarLabel')} ${
          deal?.merchant?.name || 'merchant'
        }`;
      }
      return (
        deal?.merchant && localiser.translate('priceLinkAtMerchant', [String(deal.merchant.name)])
      );
    case WidgetId.INBODY:
      return getInbodyLabel(
        deal,
        linkText,
        localiser.language,
        localiser.translate.bind(localiser),
      );
    case WidgetId.PRODUCT_BLOCK:
      return localiser.translate('shopNow');
    default:
      return '';
  }
};
