import he from 'he';

import type { WidgetRequestParameters } from '../../../assets/types/Request';
import { getSkip } from '../../../sharedModules/getSkip';
import { WidgetResult } from '../../hooks/useAnalytics';
import type { APIData } from '../../types/APIData';
import type { BattleDealOverrides } from '../../types/BattleDealOverrides';
import type { DealData } from '../../types/DealData';
import type { InitialiserResult } from '../../types/InitialiserResult';
import type { ReviewData } from '../../types/ModelInfo';
import type { Tab } from '../../types/Tab';
import { WidgetId } from '../../types/Widget';
import type { WidgetProps } from '../../types/WidgetProps';
import type WidgetFeaturesIds from '../features/types/WidgetFeaturesIds';

import { getFormattedOffers } from './getFormattedOffers';
import getModelCodeReviews from './getModelCodeReviews';
import setupTabs from './setupTabs';
import storeWidgetData from './storeWidgetData';
import type { ModelWidgetData } from './types';

const getArea = (territory: string, area: string | null): string => {
  if (area) {
    return area;
  }
  if (territory) {
    return territory;
  }
  return 'GB';
};

export const hasValidData = (
  widgetResponse: { ok: boolean },
  dealData: DealData | null,
  { showNoDeals }: Partial<WidgetFeaturesIds>,
): boolean => {
  return (
    widgetResponse.ok &&
    ((dealData && dealData.deals && dealData.deals.length > 0) || showNoDeals === true)
  );
};

export const getModelWidget = async (
  setupModelWidgetData: ModelWidgetData,
): Promise<InitialiserResult> => {
  const { model, options } = setupModelWidgetData;
  const { localiser, features, props } = options;
  const { params, site, articleName, articleUrl, widget, dataLinkMerchant, modelBrand } = props;
  const {
    defaultTab,
    tabConfigs,
    widgetResponse,
    tabData,
    battleDealOverrides,
    tabParams,
    widgetMissing,
  } = model;
  const { models, battle } = widgetResponse.ok ? widgetResponse.data : { models: {}, battle: null };

  const area = getArea(props.territory, widgetResponse.ok ? widgetResponse.data.area : null);

  const widgetProps: WidgetProps = {
    ...props,
    area,
    widgetIntroduction:
      props.widgetIntroduction &&
      he
        .decode(props.widgetIntroduction)
        .trim()
        .replace(/(\\n|\\)/g, ''),
    data: tabData,
    params: tabParams,
    tabConfigs,
    defaultTab,
    getTabConfigs: (data: APIData, params: WidgetRequestParameters): Tab[] => {
      const { tabConfigs } = setupTabs({
        data,
        params,
        features,
        widget,
        localiser,
        models,
        area,
      });
      return tabConfigs;
    },
    models,
    reviews: await (async (): Promise<ReviewData[]> => {
      const keys = Object.keys(models || {});
      const value = defaultTab ? defaultTab.value : null;
      if (value && keys && keys.length > 0 && tabData[value] && tabData[value].model_info) {
        const modelId = keys
          .map((key) => models[key])
          .reduce((result, modelId) => {
            if (!result) {
              result = modelId;
            }
            return result;
          });
        return getModelCodeReviews(
          tabData[value].model_info[modelId] || {},
          features.showReviews ?? '',
          site,
          area,
          articleUrl,
          battle,
        );
      }
      return [];
    })(),
    getDealData: async (data, overrides): Promise<DealData> => {
      data = data || {};
      overrides = overrides || battleDealOverrides;
      const offers = await getFormattedOffers({
        dataLinkMerchant,
        data,
        ...features,
        params,
        localiser,
        site,
        area,
        articleName,
        articleUrl,
        overrides: overrides as BattleDealOverrides[],
        widget,
        defaultTab,
      });

      // set up modelInfo for fallback
      const modelInfo = data?.model_info ?? {
        0: {
          brand: modelBrand,
          model_name: params?.model_name,
        },
      };

      return {
        deals: offers,
        totalDeals:
          data.counts && typeof data.counts[data.offer_type] !== 'undefined'
            ? data.counts[data.offer_type]
            : offers.length,
        dealType: data.offer_type,
        modelInfo,
      };
    },
  };
  widgetProps.dealData = await widgetProps.getDealData(
    defaultTab ? tabData[defaultTab.value] : ({} as APIData),
  );

  if (
    !widgetMissing &&
    defaultTab &&
    hasValidData(model.widgetResponse, widgetProps.dealData, features)
  ) {
    const skipWidget = getSkip(widgetProps, options, widgetProps.widget);
    if (skipWidget) {
      return {
        props: widgetProps,
        type: WidgetResult.SKIPPED,
        battle,
      };
    }
    storeWidgetData(tabData[defaultTab.value], widgetProps.widgetId, widgetProps.battleId);
    return {
      props: widgetProps,
      type: WidgetResult.SUCCESS,
      battle,
    };
  }

  const { only_fallback_offers } = params;

  // No tabs were set up, so consider the widget missing
  // unless it's a Deal widget (no deals) or fallback only
  return {
    props: widgetProps,
    type:
      options.props.widget.id === WidgetId.DEAL ||
      options.props.widget.id === WidgetId.ANF_DEAL ||
      (only_fallback_offers && defaultTab)
        ? WidgetResult.SUCCESS
        : WidgetResult.MISSING,
    battle,
  };
};
