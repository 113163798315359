import widgetConfigs from '../core/modules/widgetConfigs';
import { WidgetId } from '../core/types/Widget';
import type { Widget } from '../core/types/Widget';

export default (widgetId: WidgetId | null): Widget => {
  if (widgetId) {
    const widget = widgetConfigs.find(
      (widget) => widget.id === widgetId || (widget.aliases || []).includes(widgetId),
    );
    if (widget) {
      return widget;
    }

    if (widgetId === WidgetId.PROMOTION_FALLBACK) {
      return {
        id: WidgetId.PROMOTION_FALLBACK,
        dir: 'Promotion',
        label: 'Promotion',
        gaLabel: 'Promotion widget',
        sites: ['all'],
        group: 'default',
      };
    }
  }

  return {
    id: '' as WidgetId,
    dir: '',
    label: '',
    gaLabel: '',
    sites: [],
    group: '',
  };
};
