import { getFormattedPrice } from '../../../../../sharedModules/getFormattedPrice';
import type { Promo } from '../../../../types/Deal';
import { WidgetProps } from '../../../../types/WidgetProps';
import getPriceType from '../../../../utils/getPriceType';
import getPercentageSaving from '../../../getPercentageSaving';

interface Props {
  showWasPrice: WidgetProps['showWasPrice'];
  percentageOffThreshold: WidgetProps['percentageOffThreshold'];
  localiser: WidgetProps['localiser'];
  deal: WidgetProps['dealData']['deals'][number];
}

export const addWasPricePromos = ({
  showWasPrice,
  percentageOffThreshold,
  localiser,
  deal,
}: Props): Promo[] | undefined => {
  if (!deal) {
    return deal;
  }

  const { offer, promos } = deal;

  if (!offer?.was_price) {
    return promos;
  }

  const hasPromo = (type: string): boolean => Boolean(promos?.some((promo) => promo.type === type));
  const percentage = getPercentageSaving(deal);

  if (['promoPercentage', 'allPercentage'].includes(showWasPrice)) {
    if (hasPromo('was-price-percentage')) {
      return promos;
    }

    if (percentage && Number(percentage) >= percentageOffThreshold) {
      const value = localiser.translate('percentageDiscount', [percentage]);
      const newPromo: Promo = {
        type: 'was-price-percentage',
        value,
        display_value: value,
      };

      return [newPromo].concat(promos || []);
    }

    return promos;
  }

  if (['promoSaving', 'allSaving'].includes(showWasPrice)) {
    if (hasPromo('was-price-light')) {
      return promos;
    }

    if (!percentage || Number(percentage) < percentageOffThreshold) {
      return promos;
    }

    const savingValue = (Number(offer.was_price) - Number(offer.price)).toFixed(2);
    const formattedSaving = getFormattedPrice({
      price: savingValue,
      currencyIso: offer.currency_iso,
      showOfferLabel: false,
      type: getPriceType(deal),
      locale: localiser.language,
      translate: localiser.translate,
      showPriceRounded: 'no',
    });
    const value = localiser.translate('priceDiscount', [formattedSaving || '']);
    const newPromo: Promo = {
      type: 'was-price-light',
      value,
      display_value: value,
    };

    return (promos || []).concat(newPromo);
  }

  return promos;
};
