import { InitialiserResult } from '../../types/InitialiserResult';
import { ModelWidgetOptions } from '../../types/ModelWidgetOptions';

import { enrichModelWidgetWithAiredaleInfo } from './getAiredaleInfo';
import { getModelWidget } from './getModelWidget';
import { getModelWidgetData } from './getModelWidgetData';
import { setupBattle } from './setupBattle';
import { setupTabBasedData } from './setupTabBasedData';
import { shouldReFetchData } from './shouldReFetchData';
import type { DataModel } from './types';
import { updateWidgetConfig } from './updateWidgetConfig';

const getInitialDataModel = (): DataModel => {
  return {
    widgetResponse: { ok: false, status: 0, detail: '' },
    battleDealOverrides: [],
    battleFeatures: null,
    widgetMissing: false,
    widgetChanged: false,
    reFetchData: false,
    tabConfigs: [],
    defaultTab: null,
    tabData: {},
    tabParams: {},
  };
};

export default async (options: ModelWidgetOptions): Promise<InitialiserResult> => {
  const modelData = await getModelWidgetData({
    options,
    model: getInitialDataModel(),
  });
  const updatedModelData = updateWidgetConfig(modelData);
  const dataWithBattleTest = setupBattle(updatedModelData);
  const dataWithChangedWidget = shouldReFetchData(dataWithBattleTest);
  const tabBasedData = await setupTabBasedData(dataWithChangedWidget);
  const airedaleInfoData = await enrichModelWidgetWithAiredaleInfo(tabBasedData);
  return getModelWidget(airedaleInfoData);
};
