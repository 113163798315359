import { WidgetResult } from '../../core/hooks/useAnalytics';
import { APIFeatures, WidgetFeatures } from '../../core/modules/features';
import setupModelWidget from '../../core/modules/setupModelWidget/setupModelWidget';
import { setupSeasonalWidget } from '../../core/modules/setupSeasonalWidget/setupSeasonalWidget';
import type { InitialiserResult } from '../../core/types/InitialiserResult';
import { WidgetId } from '../../core/types/Widget';
import { WidgetInitialiserOptions } from '../../core/types/WidgetInitialiserOptions';
import { WidgetProps } from '../../core/types/WidgetProps';
import { getFilteredUrlParams } from '../../utils/getFilteredUrlParams';
import getUrlParameters from '../../utils/getUrlParameters';
import { getSkip } from '../getSkip';

import { getWidgetFromDisplayType } from './getWidgetFromDisplayType';
import getWidgetId from './getWidgetId';
import storeArticleData from './storeArticleData';

/**
 * Get the widget initialiser
 */
export const getWidgetInitialiser = async (
  options: WidgetInitialiserOptions,
): Promise<InitialiserResult> => {
  const {
    editorial,
    placeholder,
    defaultParams,
    index,
    site,
    platform,
    territory,
    localiser,
    url,
    keywords,
    getWidgetResponse,
    getSeasonalResponse,
    getAiredaleFeeds,
    getModelSuggestions,
    postForWidgetResponse,
    articleId,
    articleName,
    articleUrl,
    origin,
    dataLinkMerchant,
  } = options;
  let { attributes } = options;

  const whitelist = getFilteredUrlParams(articleUrl);
  const urlParams = getUrlParameters(whitelist);
  const widget = getWidgetFromDisplayType(attributes.displayType, attributes.widgetType);

  attributes = {
    ...(defaultParams as DOMStringMap), // cant assign our types into DOMStringMap
    ...attributes,
    ...urlParams,
  };

  const apiFeatures = new APIFeatures(
    attributes,
    widget,
    editorial,
    platform,
    localiser,
    site,
    territory,
    keywords,
    origin,
  );
  const widgetFeatures = new WidgetFeatures(
    attributes,
    widget,
    editorial,
    platform,
    localiser,
    site,
    territory,
    keywords,
  );
  /* Get the widget options - params that only affect the widget & not the API data
  Get the whole object & destructure separately so we can later assign all the
  features without calling getWidgetFeatures again */
  const features = widgetFeatures.getFeatures('id');
  const params = {
    ...apiFeatures.getFeatures('value'),
    ...apiFeatures.getLabels(),
  };

  const skipWidget = getSkip(features, options, widget);

  const props = {
    getWidgetResponse,
    getSeasonalResponse,
    getAiredaleFeeds,
    getModelSuggestions,
    postForWidgetResponse,
    widget,
    editorial,
    placeholder: placeholder ?? null,
    site,
    platform,
    territory,
    localiser,
    language: localiser.language,
    translate: localiser.translate.bind(localiser),
    url,
    index,
    articleId: articleId ?? '',
    articleName,
    articleUrl,
    keywords,
    ...features,
    params,
    widgetTypeComponent: widget.id,
    widgetId: getWidgetId(),
    dataLinkMerchant,
  } as WidgetProps; // TODO(Petr): HAWK-7003 Remove unsafe type assertion

  if (skipWidget) {
    return {
      props,
      type: WidgetResult.SKIPPED,
    };
  }

  storeArticleData(territory, localiser, params.article_type, params.article_category);

  // TODO(Petr): HAWK-4478 Widget specific, move somewhere else
  const setup = widget.id === WidgetId.SEASONAL ? setupSeasonalWidget : setupModelWidget;

  const initialiserResult = await setup({
    props,
    apiFeatures,
    widgetFeatures,
    localiser,
    features,
  });

  return initialiserResult;
};
